import { navigate } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useRef, useState } from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import LeftArrow from "../../images/blogDetails/rightarrow1.svg"
import RightArrow from "../../images/blogDetails/rightSlide.svg"
import * as styles from "./BlogDetailsSlider.module.scss"

function Arrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainer}>
      <div className={styles.portfolioArrowRightIconCover} onClick={onClick}>
        <img
          decoding="async"
          loading="lazy"
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/download_6_b9fc5971de.svg"
          }
          alt="right arrow"
          onClick={onClick}
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}
function PrevArrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainerLeft}>
      <div className={styles.portfolioArrowIconCover} onClick={onClick}>
        <img
          decoding="async"
          loading="lazy"
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/download_7_32bd55814a.svg"
          }
          alt="left arrow"
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}

function BlogDetailsSlider({
  allData,

  catagory,
}) {
  const filterData = allData?.filter(e =>
    e?.categories?.some(e => e?.name === catagory[0].name)
  )
  const data = filterData?.length < 3 ? allData : filterData

  const [current, setCurrent] = useState(0)
  const [current_1, setCurrent_1] = useState(0)
  const [current_2, setCurrent_2] = useState(0)
  const slide1 = useRef(null)

  useEffect(() => {
    ;(current === 10 - 3 || current_1 === 10 - 2 || current_2 === 10 - 1) &&
      setTimeout(() => {
        slide1?.current?.slickGoTo(0)
      }, 3000)
  }, [current, current_1, current_2])

  const settings = {
    dots: false,
    // autoplay: true,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 200,
    autoplay: 1000,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <Arrow />,
    lazyLoad: "ondemand",
    beforeChange: (current, next) => setCurrent(next),
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          beforeChange: (current, next) => setCurrent_1(next),
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          beforeChange: (current, next) => setCurrent_2(next),
        },
      },
    ],
  }

  return (
    <React.Fragment>
      <div className={`text-center ${styles.top}`}>
        <Container>
          <h2 className={styles.innerText}>Related Articles</h2>
          <hr />
        </Container>
      </div>
      <div className={styles.Slider}>
        <Container>
          <div>
            <Row className="justify-content-center">
              <Slider {...settings} ref={slide1} className={styles.SliderWidth}>
                {data?.slice(0, 10)?.map((e, i) => (
                  <div
                    className={styles.sliderText}
                    key={i}
                    onClick={() => navigate(`/blog/${e?.slug}/`)}
                  >
                    <div className={styles.detailsImg}>
                      <GatsbyImage
                        decoding="async"
                        loading="lazy"
                        image={getImage(e?.featuredImage?.localFile)}
                        alt={e?.title}
                        placeholder="blurred"
                      />
                    </div>
                    <div className={styles.text}>
                      <h3>{e?.title}</h3>
                      <p>{e?.seo?.metaDesc}</p>
                    </div>
                    <div>
                      <hr />
                    </div>
                    <div className={styles.dateTime}>
                      <div className={styles.date}>
                        <StaticImage
                          decoding="async"
                          loading="lazy"
                          src={"../../images/blogDetails/dateblue.svg"}
                          alt="date"
                        />
                        <p className={styles.innerText}>{e?.publishedOn}</p>
                      </div>
                      <div className={styles.time}>
                        <StaticImage
                          decoding="async"
                          loading="lazy"
                          src={"../../images/blogDetails/timeblue.svg"}
                          alt="time"
                        />
                        <p className={styles.innerText}>
                          {" "}
                          {e?.isWP
                            ? `${e?.readingTime || 0} minutes to read`
                            : e?.readingTime}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BlogDetailsSlider
