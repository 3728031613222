// extracted by mini-css-extract-plugin
export var Slider = "BlogDetailsSlider-module--Slider--60c50";
export var SliderWidth = "BlogDetailsSlider-module--SliderWidth--2063f";
export var date = "BlogDetailsSlider-module--date--03bef";
export var dateTime = "BlogDetailsSlider-module--dateTime--e9347";
export var detailsImg = "BlogDetailsSlider-module--detailsImg--3b6c5";
export var iconContainer = "BlogDetailsSlider-module--iconContainer--2c107";
export var iconContainerLeft = "BlogDetailsSlider-module--iconContainerLeft--bc7e2";
export var innerText = "BlogDetailsSlider-module--innerText--02b01";
export var portfolioArrowIcon = "BlogDetailsSlider-module--portfolioArrowIcon--4100b";
export var portfolioArrowIconCover = "BlogDetailsSlider-module--portfolioArrowIconCover--343f6";
export var portfolioArrowRightIconCover = "BlogDetailsSlider-module--portfolioArrowRightIconCover--3f240";
export var sliderImg = "BlogDetailsSlider-module--sliderImg--1bb71";
export var sliderText = "BlogDetailsSlider-module--sliderText--9fe48";
export var text = "BlogDetailsSlider-module--text--c2b23";
export var time = "BlogDetailsSlider-module--time--35520";
export var top = "BlogDetailsSlider-module--top--5d470";