import { graphql } from "gatsby"
import highlightShare from "highlight-share"
import * as twitterSharer from "highlight-share/dist/sharers/twitter"
import React, { useEffect } from "react"
import BlogDetailsSlider from "../components/blog-details-page/BlogDetailsSlider"
import Ecommerce from "../components/blog-details-page/Ecommerce"
import MostPopular from "../components/blog-details-page/MostPopular"
import SEORevamp from "../components/common/SEO_Revamp"
import * as styles from "../components/saas-files/blogPost.module.scss"
import MainLayout from "../layouts/MainLayout"
import "../styles/non_global/highlight-share.scss"

const BlogPostTemplate = ({ data, pageContext }) => {
  const authorBlogPost = data?.strapiBlogPost
  var changeStrapiSchema = {}
  if (
    authorBlogPost !== null &&
    authorBlogPost !== undefined &&
    Object?.keys(authorBlogPost)?.length > 0
  ) {
    changeStrapiSchema = {
      featuredImage: authorBlogPost?.featuredImage,
      post: authorBlogPost?.post,
      slug: authorBlogPost?.slug,
      title: authorBlogPost?.title,
      isWP: false,
      seo: authorBlogPost?.seo,
      isCreatePage: authorBlogPost?.isCreatePage,
      updated_at: authorBlogPost?.updatedAt,
      publishedOn: authorBlogPost?.publishedOn,
      readingTime: authorBlogPost?.readingTime,
      summary: authorBlogPost?.summary,
      categories: authorBlogPost?.categories,
      writer: authorBlogPost?.writer,
      choose_a_date: authorBlogPost?.choose_a_date,
      contributed_by: authorBlogPost?.contributed_by,
      faqs: authorBlogPost?.faqs,
      cta: authorBlogPost?.cta,
    }
  }

  const postData = { ...changeStrapiSchema }

  const allPostsData = pageContext?.allPosts
  var writerData = {}
  const chagneWriterSchema = data?.strapiWriter
  if (
    chagneWriterSchema !== null &&
    Object.keys(chagneWriterSchema).length > 0
  ) {
    writerData = {
      name: chagneWriterSchema?.name,
      slug: chagneWriterSchema?.slug,
      status: chagneWriterSchema?.status,
      description: chagneWriterSchema?.description?.rawDesc,
      image: chagneWriterSchema?.image,
      seo: chagneWriterSchema?.seo,
    }
  }

  let categoryNames = []
  postData?.categories?.map(cat => {
    categoryNames?.push(postData.isWP ? cat?.slug : cat?.attributes?.slug)
  })

  useEffect(() => {
    localStorage.setItem("popup", false)
    const selectionShare = highlightShare({
      selector: "#shareable",
      sharers: [twitterSharer],
    })
    postData?.slug && selectionShare?.init()

    return () => {
      postData?.slug && selectionShare?.destroy()
    }
  }, [])

  let headSchema = []
  const bodySchema = postData?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <div className={styles.parrent}>
        <Ecommerce
          modifiedHead={postData?.updated_at?.split("T")[0]}
          slug={postData?.slug}
          catagory={postData?.categories}
          title={postData?.title}
          featuredImage={postData?.featuredImage}
          readingTime={postData?.readingTime}
          isWP={postData?.isWP}
          choose_a_date={postData?.choose_a_date}
          publishDate={postData?.publishedOn}
          AuthorSlug={postData?.writer?.slug}
          AuthorName={postData?.writer?.name}
          AuthorImage={data?.strapiWriter?.image?.url}
          outline={postData?.summary}
          fromPage={{ slug: "blog", title: "Blog" }}
        />
        <MostPopular
          postData={postData}
          isWP={postData?.isWP}
          author={writerData}
        />
        <BlogDetailsSlider
          allData={allPostsData}
          catagory={postData?.categories}
        />
      </div>
    </MainLayout>
  )
}

export const query = graphql`
  query blogPost($slug: String, $writerSlug: String) {
    strapiBlogPost(slug: { eq: $slug }) {
      title
      slug
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
        keywords
      }
      publishedOn(formatString: "DD MMMM, YYYY")
      post: rawPost
      readingTime
      choose_a_date
      summary: rawSummary
      updatedAt(formatString: "DD MMMM, YYYY")
      featuredImage {
        alternativeText
        url
        localFile {
          childImageSharp {
            gatsbyImageData
          }
          publicURL
        }
      }
      categories {
        name
        slug
      }
      faqs {
        title
        cards {
          title
          description {
            data {
              description
            }
          }
        }
      }
      cta {
        title
        url
      }
      writer {
        name
        slug
        status
        description {
          data {
            description
          }
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      contributed_by {
        name
        slug
        status
        isCreatePage
        image {
          alternativeText
          url
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    strapiWriter(slug: { eq: $writerSlug }) {
      name
      slug
      status
      description: rawDesc
      seo {
        metaDescription
      }
      image {
        alternativeText
        url
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

export default BlogPostTemplate

export const Head = ({ data }) => {
  const url = `https://invozone.com/blog/${data?.strapiBlogPost?.slug}/`
  let headSchema = []
  const bodySchema = data?.strapiBlogPost?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <SEORevamp
      title={data?.strapiBlogPost?.seo?.metaTitle}
      description={data?.strapiBlogPost?.seo?.metaDescription}
      image={`https://invozone.com${
        data?.strapiBlogPost?.isWP
          ? data?.strapiBlogPost?.featuredImage?.sourceUrl
          : data?.strapiBlogPost?.featuredImage?.localFile?.publicURL
      }`}
      schemas={headSchema}
      url={url}
      keywords={data?.strapiBlogPost?.seo?.keywords}
    />
  )
}
